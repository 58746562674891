import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import DemoBanner from '../components/DemoBanner';
import TextHero from '../components/TextHero';
import ResourceDetail from '../components/ResourceDetail'
import ResourcesSection from '../components/ResourcesSection';
import SiteMeta from '../components/SiteMeta';

const ResourcePage = ({ data, pageContext }) => {

  const {
    contentfulResource: {
      metaTitle,
      metaDescription: {
        metaDescription
      },
      title,
      author,
      sidebarRelatedResource,
      relatedResources,
      form
    },
    contentfulResourceDetailPage: {
      demoCallout
    }

  } = data;

  const heroData = {
    title: title,
    subtitle: author,
  }



  return (
    <Layout>
      <SiteMeta description={metaDescription} title={metaTitle} />
      <TextHero {...heroData} variant={'left-aligned'} />
      <ResourceDetail {...data.contentfulResource} form={form} sidebarResource={sidebarRelatedResource} />

      <ResourcesSection title={'Related articles'} subtitle={null} featuredResources={relatedResources} />
    </Layout>
  )
}

export default ResourcePage;

export const query = graphql`
  query ResourcePageQuery($contentful_id : String!) {
    contentfulResource(contentful_id : { eq: $contentful_id  }){
      ...ResourceDetail
      form {
        ...Form
      }
    }
    contentfulResourceDetailPage {
      demoCallout {
        ...Navigator
      }
      form {
        ...Form
      }
    }
  }
`;
